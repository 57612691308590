import { graphql } from 'gatsby'
import { PageHorizontalNav } from '../components/PageHorizontalNav/PageHorizontalNav'
import PageLayout from '../components/PageLayout/PageLayout'
import SubpageHeader from '../components/SubpageHeader/SubpageHeader'
import { getMetaTags } from '../utils/metaTags'
import FeaturesZoneRenderer from '../zones/FeaturesZoneRenderer'

export default function LayoutFeatures({ pageContext, data, location }) {
  const zones = data.strapiGQL.feature.contentZones
  return (
    <PageLayout>
      <PageHorizontalNav parent="features" />
      <SubpageHeader
        title={pageContext.title}
        summary={pageContext.subtitle}
        metaTags={getMetaTags(pageContext)}
      />
      {zones && <FeaturesZoneRenderer zones={zones} />}
    </PageLayout>
  )
}

export const pageQuery = graphql`
  query ($id: ID!) {
    strapiGQL {
      feature(id: $id) {
        ...StrapiGQL_FeaturesWithContentZones
      }
    }
  }
`
